<!-- User List -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="2" title="Users">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <GgPageHeader
        :userInfo="userInfo"
        :title="`${userInfo.firstName} ${userInfo.lastName}`"
        class="mar-b-0"
        @_uploadCallbackLogo="_uploadCallbackLogo"
      >
        <i
          slot="title-label"
          class="title-icon iconfont iconedit"
          @click="openDialogAdd"
        ></i>
        <div slot="info">
          <div class="account-info">
            <div class="account-create">
              Created : {{ dateFormat(userInfo.created) }}
            </div>
            <div class="account-sign">
              Last sign in : {{ timeFormat(userInfo.lastLoginTime) }}
            </div>
          </div>
          <div class="title-status">{{ userInfo.status[0].toUpperCase()
            }}{{ userInfo.status.substring(1) }}</div>
          <div class="account-editbtn">
            <div class="editbtn-wrapper mar-r-20" @click="openDialogPwd">
              <span class="iconfont iconlock-reset2 icon"></span>
              <span class="editbtn-text">Reset Password</span>
            </div>
            <div class="editbtn-wrapper" @click="handleDelete">
              <span class="iconfont iconaccount-minus icon"></span>
              <span class="editbtn-text">Delete user</span>
            </div>
          </div>
        </div>
      </GgPageHeader>
      <border-less-table v-if="isAppAccess" :data="personalInfoData" />
      <edoovo-table
        v-if="!isAppAccess"
        height="auto"
        ref="appAccessTable"
        :data="tableData"
        rowKey="id"
        defaultValue="-"
        class="list-tableBox"
      >
        <edoovo-table-column
          size="M"
          label="Apps"
          class="user_name"
          prop="moduleName"
          :tooltip="false"
          v-slot="{ row }"
        >
          <div>
            <head-img
              class="table-cell-img"
              :src="row.avatar ? `${row.avatar}?${+new Date()}` : ``"
              line-height="24"
            />
            <span class="table-cell-name">{{ row.moduleName }}</span>
          </div>
        </edoovo-table-column>
        <edoovo-table-column
          :tooltip="false"
          size="S"
          label="Status"
          prop="status"
        />
      </edoovo-table>
    </GgPage>
    <!-- resetpassword -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          :autoSend="formDialogPwd.autoSend"
          @sendEmail="resetByEmail"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      textReminder="* indicates a required field"
      :renderPass="formDialogAdd.renderPass"
      :ref="formDialogAdd.ref"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'photo'" class="upload-imgbox">
          <div
            class="upload-img"
            @click="isImgCutShow = true"
            :ref="scope.data.key"
          >
            <head-img
              v-if="formDialogAdd.dataRender.photo"
              :src="formDialogAdd.dataRender.photo"
              line-height="88"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
        <baseGGSelect
          v-else-if="scope.data.key == 'gender'"
          v-model="formDialogAdd.dataRender.gender"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
          :error="scope.data.error"
        >
          <md-option
            v-for="(item, index) in scope.data.genderList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key == 'nationality'"
          v-model="formDialogAdd.dataRender.nationality"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <gg-input
          v-else-if="scope.data.key == 'account'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.account"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.accountErrorObj"
        />
        <PhoneNumber
          v-else-if="scope.data.key == 'tel'"
          v-model="formDialogAdd.dataRender.tel"
          :ref="scope.data.key"
        ></PhoneNumber>
        <baseGGSelect
          v-else-if="scope.data.key == 'jobTitle'"
          v-model="formDialogAdd.dataRender.jobTitle"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key == 'workType'"
          v-model="formDialogAdd.dataRender.workType"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          :class="{ float: scope.data.float }"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
      </template>
      <template slot="footer">
        <baseButton @click="handleAddCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddSubmit"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
    <!-- 给创建的用户权限 -->
    <BaseFormDialog1
      v-model="formDialogAccess.visible"
      :title="formDialogAccess.title"
      :data="formDialogAccess.dataList"
      :renderPass="formDialogAccess.renderPass"
      :ref="formDialogAccess.ref"
      textReminder="* indicates a required field"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key == 'adminConsole'">
          <div class="assigned-box">
            <div class="assigned-box-left">
              <base-img
                class="menuIcon"
                :src="
                  require(`@/assets/images/menu/${_getSvg(
                    'Admin Console'
                  )}.svg`)
                "
                alt
              />
              Admin console
            </div>
            <!-- @change="_changeSwitch" -->
            <div>
              <gg-switch
                v-model="scope.data.assigned"
                active-color="#1A73E8"
                inactive-color="#B4B4B4"
              />
              <div class="switch-txt">Assigned</div>
            </div>
          </div>
          <edoovo-table
            v-if="scope.data.assigned"
            height="auto"
            ref="assignedTable"
            :data="scope.data.tableData"
            rowKey="id"
            defaultValue="-"
            class="list-tableBox mar-t-20"
          >
            <edoovo-table-column
              size="L"
              label="Apps"
              class="user_name"
              prop="apps"
              :tooltip="false"
              v-slot="{ row }"
              @click="() => {}"
            >
              <div>
                <base-img class="table-cell-img" :src="row.avatar" />
                <span class="table-cell-name">{{ row.apps }}</span>
              </div>
            </edoovo-table-column>
            <edoovo-table-column
              size="S"
              :tooltip="false"
              label="Status"
              prop="status"
            />
          </edoovo-table>
        </div>
        <!-- :error="isError" -->
        <div v-else-if="scope.data.key == 'roleId'">
          <baseGGSelect
            v-model="formDialogAccess.dataRender.roleId"
            class="mar-t-20 mar-b-20"
            :placeholder="scope.data.placeholder"
            :ref="scope.data.key"
          >
            <md-option
              v-for="(item, index) in scope.data.roleList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </md-option>
          </baseGGSelect>
          <edoovo-table
            v-if="selectedRole"
            height="auto"
            ref="appAccessTable"
            :data="scope.data.tableData"
            rowKey="id"
            defaultValue="-"
            class="list-tableBox mar-t-10 mar-b-20"
          >
            <edoovo-table-column
              size="M"
              label="Apps"
              class="user_name"
              prop="moduleName"
              :tooltip="false"
              v-slot="{ row }"
              @click="() => {}"
            >
              <div>
                <head-img
                  class="table-cell-img"
                  :src="
                    require(`@/assets/images/menu/${_getSvg(
                      row.moduleName
                    )}.svg`)
                  "
                  line-height="24"
                />
                <span class="table-cell-name">{{ row.moduleName }}</span>
              </div>
            </edoovo-table-column>
            <edoovo-table-column size="S" :tooltip="false" label="Status"
              >On</edoovo-table-column
            >
          </edoovo-table>
        </div>
      </template>
      <template slot="footer">
        <baseButton @click="handleAccessCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAccess.isDisabled"
          @click="handleAccessForm"
          >{{ formDialogAccess.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
import { menuIconParams } from "@/common/baseData";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      userId: "",
      isAppAccess: true,
      breadcrumbData: [],
      menuDatas: [
        {
          name: "Personal info",
          key: "personalInfo",
          active: true,
          children: [],
        },
        {
          name: "App access",
          key: "appAccess",
        },
      ],
      activeIndex: "personalInfo",
      userInfo: {
        firstName: "",
        lastName: "",
        status: "",
        created: "",
        lastLoginTime: "",
        email: "",
      },
      personalInfoData: [
        { label: "First Name", value: "", id: "firstName" },
        { label: "Last Name", value: "", id: "lastName" },
        { label: "Email", value: "", id: "account" },
        { label: "Phone", value: "", id: "phone" },
        { label: "Job title", value: "", id: "jobTitle" },
        { label: "Working type", value: "", id: "workType" },
      ],
      tableData: [
        {
          moduleName: "Companies",
          avatar: require("@/assets/images/menu/companies.svg"),
          status: "Off",
        },
        {
          moduleName: "Parents",
          avatar: require("@/assets/images/menu/parents.svg"),
          status: "Off",
        },
      ],
      snackbarData: {
        visible: false,
        content: "",
      },
      isImgCutShow: false,
      formDialogPwd: {
        visible: false,
        data: "",
        name: "",
        autoSend: true,
        isDisabled: false,
      },
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "",
        add: "Add user",
        edit: "Edit user",
        submitBtnTxt: "Next",
        renderPass: false,
        slotData: ["gender", "nationality", "jobTitle", "workType", "account"],
        isDisabled: true,
        dataRender: {
          photo: "",
          account: "",
          gender: "",
          nationality: "",
          tel: {
            countryCode: "+65",
            phoneNumber: "",
          },
          jobTitle: "",
          workType: "",
          password: "",
          sendLink: false,
        },
        accountErrorObj: {
          show: false,
          message: "",
        },
        dataList: [
          {
            placeholder: "photo",
            key: "photo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            float: true,
            reg: /\S/,
            errorText: "First name is required",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /\S/,
            errorText: "Last name is required",
          },
          {
            placeholder: "Gender *",
            key: "gender",
            value: "",
            genderList: ["Male", "Female"],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Nationality *",
            key: "nationality",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Email *",
            key: "account",
            value: "",
            float: true,
            renderHtml: true,
            // reg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
            // errorText: "email is invalid",
          },
          {
            placeholder: "Phone number *",
            key: "tel",
            value: {
              countryCode: "+65",
              phoneNumber: "",
            },
            renderHtml: true,
          },
          {
            placeholder: "Job title",
            key: "jobTitle",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
          {
            placeholder: "Working type",
            key: "workType",
            value: "",
            itemList: [],
            float: true,
            renderHtml: true,
          },
        ],
        formData: {},
      },
      formDialogAccess: {
        ref: "formDialogAccess",
        visible: false,
        title: "",
        add: "Add user",
        edit: "Edit user",
        submitBtnTxt: "Save",
        renderPass: false,
        isDisabled: true,
        dataRender: {
          roleId: "",
          adminConsole: [
            { lable: "Users", value: "On" },
            { lable: "Settings", value: "On" },
          ],
        },
        dataList: [
          {
            key: "adminConsole",
            assigned: false,
            tableData: [
              {
                apps: "Users",
                avatar: require("@/assets/images/menu/users.svg"),
                status: "On",
              },
              {
                apps: "Settings",
                avatar: require("@/assets/images/menu/settings.svg"),
                status: "On",
              },
            ],
            renderHtml: true,
          },
          {
            placeholder: "Role",
            key: "roleId",
            value: "",
            roleList: [],
            tableData: [],
            renderHtml: true,
          },
        ],
      },
    };
  },
  watch: {
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
    "formDialogAdd.dataRender.account": function (res) {
      let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(res);
      if (res.length === 0) {
        this.formDialogAdd.accountErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.formDialogAdd.accountErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.formDialogAdd.accountErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          const isRender = res.slotData.every(
            // 插槽数据是否通过校验
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          let isPhone = false; // phoneNumber是否通过校验

          if (this.$refs.tel) {
            isPhone =
              !!this.$refs.tel.value.phoneNumber &&
              !this.$refs.tel.isError.show;
          } else {
            isPhone = false;
          }

          res.renderPass = res.isDisabled =
            isRender && isForm && isPhone && !this.$refs.account.error.show;
          console.log("renderPass:", this.formDialogAdd.renderPass);
        });
      },
    },
    formDialogAccess: {
      deep: true,
      handler(res) {
        if (!!res.dataRender.roleId) {
          res.renderPass = res.isDisabled = true;
        } else {
          res.renderPass = res.isDisabled = false;
        }
      },
    },
    selectedRole(roleId) {
      this.selectRoleById(roleId);
    },
  },
  computed: {
    selectedRole() {
      return this.formDialogAccess.dataList.find((i) => i.key == "roleId")
        .value;
    },
  },
  methods: {
    timeFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY, H:M A");
    },
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
    _getSvg(menu) {
      if (menuIconParams[menu] && menuIconParams[menu].svg) {
        return menuIconParams[menu].svg;
      }
      return "product_app";
    },
    getRoleList() {
      Ajax.get("/usermanage/role/getRoleByBelongId", {
        userId: this.userId,
      })
        .then((res) => {
          this.formDialogAccess.dataList.find(
            (i) => i.key === "roleId"
          ).roleList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    _uploadCallbackLogo(val) {
      this.userInfo.avatar = val;
      let params = {
        photo: val,
        id: this.userInfo.id,
        // userId: this.$store.state.user.userId,
      };
      Ajax.post("/usermanage/superAdmin/updateSuperAdmin", params).then(
        (res) => {
          if (res.code === "0000") {
            this.getDataPersonalInfo();
            this.handleSnack("User has been edited");
          }
        }
      );
    },
    menuSelect(key, item) {
      this.activeIndex = key;

      if (key === "appAccess") {
        this.getDataUserAppAccess();
        this.isAppAccess = false;
      } else {
        this.getDataPersonalInfo();
        this.isAppAccess = true;
      }
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    selectRoleById(roleId) {
      Ajax.get("/usermanage/role/selectRoleById", {
        roleId: roleId,
      })
        .then((res) => {
          this.formDialogAccess.dataList.find(
            (i) => i.key === "roleId"
          ).tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd() {
      this.formDialogPwd.name = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      let params = {
        userId: this.userId,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      };
      Ajax.post("/usermanage/password/resetPasswd", params).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Password has been reset");
          this.formDialogPwd.data = "";
        }
      });
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.formDialogAdd.dataRender.photo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    handleDelete() {
      this.$confirm(
        "Once the user is deleted, the user will not be able to access the app.",
        "Delete user ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/superAdmin/updateStatus", {
            status: "delete",
            userId: this.userId,
          }).then((res) => {
            if (res.code === "0000") {
              this.snackbarData = {
                visible: true,
                content: "User has been deleted",
              };
              this.$router.push({ name: "users" });
            }
          });
        })
        .catch(() => {});
    },
    getDataUserAppAccess() {
      Ajax.get("/usermanage/superAdmin/selectCompanyPermissionByUserId", {
        userId: this.userId,
      }).then((res) => {
        if (res.code === "0000") {
          this.tableData.map((j) => (j.status = "Off"));
          res.data.forEach((role) => {
            this.tableData.forEach((item) => {
              if (role.moduleName === item.moduleName) {
                item.status = "On";
              }
            });
          });
        }
      });
    },
    getDataPersonalInfo() {
      Ajax.get("/usermanage/superAdmin/selectDetailByUserId", {
        userId: this.userId,
      }).then((res) => {
        if (res.code === "0000") {
          this.personalInfoData.forEach((item) => {
            this.userInfo = { ...res.data };
            this.userInfo.avatar = res.data["photo"];
            this.breadcrumbData = [
              {
                routeName: "users",
                name: "Users",
              },
              {
                name: `${res.data["firstName"]} ${res.data["lastName"]}`,
              },
            ];
            if (res.data[item.id]) {
              item.value = res.data[item.id];
            }
            if (item.id === "phone") {
              item.value = `${res.data["countryCode"]}${res.data["phoneNumber"]}`;
            }
          });
        }
      });
    },
    fillData() {
      let data = {};
      const assigned = this.formDialogAccess.dataList.find(
        (item) => item.key === "adminConsole"
      ).assigned;
      if (!assigned) {
        this.formDialogAccess.dataRender.adminConsole = [];
      } else {
        this.formDialogAccess.dataRender.adminConsole = [
          { lable: "Users", value: "On" },
          { lable: "Settings", value: "On" },
        ];
      }
      data = Object.assign(
        {},
        this.formDialogAdd.formData,
        this.formDialogAccess.dataRender,
        {
          userId: this.$store.state.user.userId,
        }
      );
      return data;
    },
    openDialogAdd() {
      this.formDialogAdd.visible = true;
      this.formDialogAdd.title = this.formDialogAdd.edit;
      this.formDialogAdd.submitBtnTxt = "Next";
      this.formDialogAdd.id = this.userInfo["id"];
      this.formDialogAdd.renderPass = false;
      this.formDialogAdd.dataList.forEach((i) => {
        if (this.userInfo[i.key]) {
          i.value = this.userInfo[i.key];
        }
      });

      Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
        this.formDialogAdd.dataRender[item] = this.userInfo[item];
      });

      this.formDialogAdd.dataRender.tel = {
        countryCode: this.userInfo["countryCode"],
        phoneNumber: this.userInfo["phoneNumber"],
      };

      this.formDialogAccess.dataRender.roleId = this.userInfo["roleId"];

      this.formDialogAccess.dataList.forEach((item1) => {
        if (item1.key === "adminConsole") {
          if (
            !this.userInfo["adminConsole"] ||
            JSON.parse(this.userInfo["adminConsole"]).length === 0
          ) {
            item1.assigned = false;
          } else {
            item1.assigned = true;
          }
        }
      });
    },
    handleAddCancel() {
      this.formDialogAdd.visible = false;
    },
    handleAddSubmit() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });

      this.formDialogAdd.formData = Object.assign(
        {},
        obj,
        this.formDialogAdd.dataRender
      );

      this.formDialogAdd.formData.countryCode = this.formDialogAdd.dataRender.tel.countryCode;
      this.formDialogAdd.formData.phoneNumber = this.formDialogAdd.dataRender.tel.phoneNumber;
      this.formDialogAdd.formData.isAuto = this.formDialogAdd.dataRender
        .sendLink
        ? "1"
        : "0";
      // 邮箱唯一性校验
      let params = {
        email: this.formDialogAdd.dataRender.account,
        type: 1,
        userId: this.formDialogAdd.id,
      };

      Ajax.post("/usermanage/email/isUsingEmail", params)
        .then((res) => {
          if (res.data) {
            this.formDialogAdd.accountErrorObj = {
              show: true,
              message: "Email already exists",
            };
          } else {
            this.formDialogAdd.visible = false;
            this.formDialogAccess.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAccessCancel() {
      this.formDialogAccess.visible = false;
    },
    handleAccessForm() {
      let params = {};

      params = Object.assign({}, this.fillData(), {
        id: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/superAdmin/updateSuperAdmin", params).then(
        (res) => {
          if (res.code === "0000") {
            this.getDataPersonalInfo();
            this.handleSnack("User has been edited");
            this.formDialogAccess.visible = false;
          }
        }
      );
    },
    getSettingList(dataLevel, dataType) {
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
        userId: this.userId,
      };

      Ajax.post("/usermanage/setting/getList", params).then((res) => {
        let arr = [];
        // let itemList = [];
        res.data.forEach((item1) => {
          this.formDialogAdd.dataList.forEach((item2) => {
            if (item1.dataType === item2.key) {
              item2.itemList.push(item1.value);
            }
          });
        });
      });
    },
  },
  created() {
    this.userId = this.$route.query.id;
    this.getRoleList();
    this.getDataPersonalInfo();
    this.getSettingList("user", "workType");
    this.getSettingList("user", "jobTitle");
    this.getSettingList("general", "nationality");
  },
};
</script>
<style lang="less">
.assigned-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  color: #000;
  &-left {
    display: flex;
    align-items: center;
    .menuIcon {
      margin-right: 8px;
      width: 32px;
    }
  }
}
</style>
<style lang="less" scoped>
.phone-number-content {
  margin-top: 15px;
  margin-bottom: 0;
  width: 100%;
  float: left;
}
.table-cell-img {
  width: 24px;
  height: 24px;
  /deep/ img {
    height: 100%;
    width: auto;
  }
}
</style>